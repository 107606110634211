<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>欢迎你 {{$store.state.user.Name}}，今天是{{new Date().toUTCString()}}</span>
      </div>
      <div class="count-box">
        <div class="bg-primary">
          <span>
            <font-awesome-icon fas icon="user-astronaut" style="width:80px;height:80px;"></font-awesome-icon>
            <div style="font-weight:bold;margin-top:10px;">日用户量</div>
          </span>
          <span class="count">{{countInfo.users}}</span>
        </div>
        <div class="bg-success">
          <span>
            <font-awesome-icon fas icon="dollar-sign" style="width:80px;height:80px;"></font-awesome-icon>
            <div style="font-weight:bold;margin-top:10px;">日订单数</div>
          </span>
          <span class="count">{{countInfo.orders}}</span>
        </div>
        <div class="bg-warning">
          <span>
            <font-awesome-icon fas icon="comment-dollar" style="width:80px;height:80px;"></font-awesome-icon>
            <div style="font-weight:bold;margin-top:10px;">日购票量</div>
          </span>
          <span class="count">{{countInfo.tickets}}</span>
        </div>
        <div class="bg-danger">
          <span>
            <font-awesome-icon fas icon="money-check-alt" style="width:80px;height:80px;"></font-awesome-icon>
            <div style="font-weight:bold;margin-top:10px;">日金额</div>
          </span>
          <span class="count">{{countInfo.totalAmount}}</span>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import API from '../../apis/ticket-api'
import { WELCOME } from '../../router/base-router'

export default {
  name: WELCOME.name,
  data () {
    return {
      countInfo: {
        users: 108,
        orders: 100,
        tickets: 300,
        totalAmount: 10000
      } //  统计数据
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.init())
  },
  methods: {
    init () {
      return false
    },
    getCount () {
      this.axios.get(API.ORDER.COUNT_URL).then(response => {
        this.countInfo = response
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$primary-color: #409EFF;
$secondary-color:#2c3e50;
$danger-color: #f56c6c;
$success-color: #67c23a;
$warning-color: #FEAE1B;

.bg-primary {
  background-color: $primary-color !important;
}

.bg-secondary {
  background-color: $secondary-color !important;
}

.bg-danger {
  background-color: $danger-color !important;
}

.bg-success {
  background-color: $success-color !important;
}

.bg-warning {
  background-color: $warning-color !important;
}

.el-card {
  margin-bottom: .75rem;
}

.count-box {
  display: flex;
  justify-content: space-around;
  align-items: center;

  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 240px;
    height: 90px;
    color: #fff;
    font-size: 16px;
  }

  .count {
    font-size: 34px;
    font-weight: bold;
  }
}
.row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.order-box {
  flex: 3;
}

.article-box {
  flex: 1;
  min-width: 400px;
  margin-left: 20px;
}

.el-card {
  .title {
    font-size: 16px;
    color: #666;
  }
}
</style>
